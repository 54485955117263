@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: #15161c;
  color: #ffffff;
  font-family: TTMusselsRegular, 'Ubuntu', 'Cantarell', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}

ol {
  counter-reset: item;
}

ol > li:before {
  content: counters(item, '.') '. ';
  counter-increment: item;
}

li > ol > li:before {
  content: counters(item, '.') ' ';
  counter-increment: item;
}

tr:nth-child(even) {
  background-color: #2a2c39;
}

tr:nth-child(odd) {
  background-color: #1f2129;
}

@font-face {
  font-family: TTMusselsDemiBold;
  src: local(TTMusselsDemiBold),
    url('./assets/TT Mussels DemiBold.ttf') format('truetype');
}

@font-face {
  font-family: TTMusselsRegular;
  src: local(TTMusselsRegular),
    url('./assets/TT Mussels Regular.ttf') format('truetype');
}

@layer base {
  h1 {
    font-family: TTMusselsDemiBold;
    font-style: uppercase;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
  }

  h2 {
    font-family: TTMusselsDemiBold;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
  }

  h3 {
    font-family: TTMusselsDemiBold;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: #ffffff;
  }

  h4 {
    font-family: TTMusselsDemiBold;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #70758e;
    text-transform: uppercase;
  }

  h5 {
    font-family: TTMusselsDemiBold;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #abb0c6;
  }

  .bold16 {
    font-family: TTMusselsDemiBold;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
  }

  .bold18 {
    font-family: TTMusselsDemiBold;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #ffffff;
  }

  .regular14 {
    font-family: TTMusselsRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    @apply text-coldGrey-800;
  }

  .regular16 {
    font-family: TTMusselsRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    @apply text-coldGrey-800;
  }

  .medium16 {
    font-family: TTMusselsDemiBold;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }

  .medium18 {
    font-family: TTMusselsDemiBold;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
  }

  .bg-blue {
    @apply bg-primaryBlue-200;
  }

  .text-logo {
    font-family: TTMusselsDemiBold;
    font-weight: 400;
    font-size: 28px;
    text-decoration: none;
    @apply text-white;
  }

  .text-blue {
    @apply text-primaryBlue-900;
  }

  .text-xxs {
    font-size: 10px;
    line-height: 1rem;
  }

  .shadow-shiny {
    box-shadow: 0px 0px 6px #40b0c6;
  }

  .shadow-thanks {
    filter: drop-shadow(0px 11px 19px rgba(29, 226, 238, 0.8));
  }

  .border-shiny {
    @apply shadow-shiny;
    border: 2px solid;
    @apply border-turquoise-800;
  }

  .border-shiny-transparent {
    border: 2px solid;
    @apply border-coldGrey-300;
  }

  .border-shiny-right {
    @apply shadow-shiny;
    border-right: 2px solid;
    @apply border-turquoise-800;
  }

  .bg-thanks,
  .bg-404 {
    background: linear-gradient(
      180deg,
      #7e7e7e 0%,
      #03a9f4 41.15%,
      #10f1ff 62.5%,
      #2f9573 98.44%
    );
    opacity: 0.8;
    mix-blend-mode: soft-light;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -10;
  }

  .modal-bg {
    backdrop-filter: blur(8px);
    z-index: 40;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000080;
  }

  .modal {
    position: absolute;
    outline: 0;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    @apply border border-coldGrey-400;
  }

  .spinner {
    display: inline-block;
    content: ' ';
    display: block;
    border-radius: 50%;
    border: 3px solid #fff;
    @apply border-y-coldGrey-800;
    @apply border-x-[#00000000];
    animation: spinner 1s linear infinite;
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .center-xy {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .center-y {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }

  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  input.labeled + div {
    left: 14px;
    top: 16px;
    font-size: 12px;
    @apply text-coldGrey-700;
    @apply transition-all;
  }
  input.labeled:not(:focus):placeholder-shown + div {
    left: 14px;
    font-size: 16px;
    @apply center-y;
    @apply text-coldGrey-700;
    @apply transition-all;
  }

  .bg-wire-active {
    background: radial-gradient(
      36.22% 162.98% at 50% 50%,
      #ffffff 0%,
      #f1fcfa 10%,
      #d1f7eb 29%,
      #c2ffeb 49%,
      #40b0c6 77%,
      #005e71 100%
    );
    color: #15161c;
  }

  .click-highlight {
    @apply hover:bg-coldGrey-400 active:bg-coldGrey-500 rounded-full;
  }

  .shadow-transparent30 {
    box-shadow: inset 0 0 100px 100px #b8f3ff4d;
  }

  .shadow-transparent10 {
    box-shadow: inset 0 0 100px 100px #b8f3ff1a;
  }
}
